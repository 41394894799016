<template>
  <v-container fill-height>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="6" lg="4">
        <v-card class="elevation-12 text-center">
          <v-toolbar flat dark color="primary">
            <v-toolbar-title>Votre profil</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-avatar size="62" color="primary">
                  <v-icon large dark>
                    mdi-account-circle
                  </v-icon>
                </v-avatar>
              </v-col>
              <v-col cols="12">
                <h2 class="primary--text">{{ user.email }}</h2>
              </v-col>
              <v-col class="text-left">
                <UserAccesses />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" v-if="currentPermissions.includes('authorizations.access')">
                <ManageAccesses />
              </v-col>
              <v-col cols="12">
                <ChangePassword />
              </v-col>
            </v-row>
          </v-card-text>

          <Logout />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  components: {
    UserAccesses: () => import("@/components/User/UserAccesses"),
    ManageAccesses: () => import("@/components/User/ManageAccessesBtn"),
    ChangePassword: () => import("@/components/User/ChangePasswordBtn"),
    Logout: () => import("@/components/User/Logout"),
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      targets: (state) => state.account.targets,
      currentPermissions: (state) => state.account.currentPermissions,
    }),
  },
};
</script>
